import _cookies from './CookiesService';
import { Buffer } from 'buffer';

const axios = require('axios').default;


export const get = async (path, data) => {
    try {
        let response = await axios.put(`${getApiUrl()}${path}`, data, getHeaders());
        return response;
    } catch (e) {
        return e.response;
    }
}

export const getData = async (path) => {
    try {
        let response = await axios.get(`${getApiUrl()}${path}`, getHeaders());
        return response;
    } catch (e) {
        return e.response;
    }
}


export const post = async (path, data) => {
    try {
        let response = await axios.post(`${getApiUrl()}${path}`, data, getHeaders());
        return response;
    } catch (e) {
        return e.response;
    }
}
export const put = async (path, data) => {
    try {
        let response = await axios.put(`${getApiUrl()}${path}`, data, getHeaders());
        return response;
    } catch (e) {
        return e.response;
    }
}

export const postFacturama = async (path, data) => {
    try {
        let response = await axios.post(`${getApiFacturama()}${path}`, data, setFacturamaHttpOptions());
        return response;
    } catch (e) {
        return e.response;
    }
}

export const getFacturama = async (path) => {
    try {
        let response = await axios.get(`${getApiFacturama()}${path}`, setFacturamaHttpOptions());
        return response;
    } catch (e) {
        return e.response;
    }
}

const getHeaders = () => {
    let headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return headers;
}

const setFacturamaHttpOptions = () => {
    const token = `${process.env.REACT_APP_FACT_USER}:${process.env.REACT_APP_FACT_PASSWORD}`;
    const encodedToken = Buffer.from(token).toString('base64');
    let items = {
        'Content-type': 'application/json',
        'Authorization': 'Basic ' + encodedToken
    }
    const headers = {
        headers: items
    }

    return headers;
}
const setHttpOptions = () => {
    const token = getToken();

    let items = {
        'Content-type': 'application/json'
    }

    if (token && token.length) {
        items['Authorization'] = `Bearer ${token}`;
    }

    const headers = {
        headers: items
    }

    return headers;
}



const getToken = () => {
    let user = JSON.parse(_cookies.getC('data'));

    let resp = "";

    if (user) {
        resp = user.access_token;
    }
    return resp;
}


const getApiUrl = () => {
    return process.env.REACT_APP_API_PATH;
}

const getApiFacturama = () => {
    return process.env.REACT_APP_API_FACT
}


