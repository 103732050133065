import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CryptoJS from 'crypto-js';

import { post, getData } from "./core/services/ConnectionService";
import { confirm, createLoading, showAfterLoading, toastDisplay, toastDisplayXL } from './core/services/SweetAlerService';

import yupikLogo from "./assets/img/logos/logo.png";
import icn_money from "./assets/img/icons/icn_money.png";
import icn_rfc from "./assets/img/icons/icn_rfc.png";
import icn_user from "./assets/img/icons/icn_user.png";
import icn_email from "./assets/img/icons/icn_email.png";
import icn_info from "./assets/img/icons/icn_info.png";


function App() {

  const [search, setSearch] = useState(useLocation().search);
  const [establishment, setEstablishment] = useState({
    cp: "",
    establishment_id: "",
    facturama_id: 0,
    name: "",
    pic: "",
    regime: "",
    rfc: ""
  });
  const [establishmentId, setEstablishmentId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [ticket, setTicket] = useState(null);

  const [rfc, setRfc] = useState('');
  const [name, setName] = useState('');
  const [use, setUse] = useState('');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [taxRegime, setTaxRegime] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [wayToPay, setWayToPay] = useState('');
  const [kindOfPerson, setKindOfPerson] = useState('');
  const [rfcError, setRfcError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [useError, setUseError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  const [useTypeUser, setUseTypeUser] = useState('');
  const [useCfdi, setUseCfdi] = useState([
    { key: 'G01', value: 'Adquisición de mercancias' },
    { key: 'G02', value: 'Devoluciones, descuentos, bonificaciones' },
    { key: 'G03', value: 'Gastos en general' },
    { key: 'I01', value: 'Construcciones' },
    { key: 'I02', value: 'Mobiliario y equipo de oficina por inversiones' },
    { key: 'I03', value: 'Equipo de transporte' },
    { key: 'I04', value: 'Equipo de cómputo y accesorios' },
    { key: 'I05', value: 'Dados, troqueles, moldes, matrices y herramental' },
    { key: 'I06', value: 'Comunicaciones telefónicas' },
    { key: 'I07', value: 'Comunicaciones satelitales' },
    { key: 'I08', value: 'Otra maquinaria y equipo' },
    { key: 'P01', value: 'Por definir' },
    { key: 'D01', value: 'Honorarios médicos, dentales y gastos hospitalarios' },
    { key: 'D02', value: 'Gastos médicos por incapacidad o discapacidad' },
    { key: 'D03', value: 'Gastos funerarios' },
    { key: 'D04', value: 'Donativos' },
    { key: 'D05', value: 'Intereses por créditos hipotecarios' },
    { key: 'D06', value: 'Aportaciones voluntarias al SAT' },
    { key: 'D07', value: 'Primas por seguros de gastos médicos' },
    { key: 'D08', value: 'Gastos de transportación escolar obligatoria' },
    { key: 'D09', value: 'Depósitos de cuenta para el ahorro, primas con base' },
    { key: 'D10', value: 'Pagos por servicios educativos (colegiaturas)' },
  ]);
  const [auxUseCfdi, setAuxUseCfdi] = useState([]);
  const [invoiceUse, setInvoiceUse] = useState(0);

  const taxRegimeOptions = {
    601: "601 - Régimen General de Ley Personas Morales",
    605: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
    606: "606 - Arrendamiento",
    607: "607 - Régimen de Enajenación o Adquisición de Bienes",
    608: "608 - Demás ingresos",
    610: "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
    611: "611 - Ingresos por Dividendos (socios y accionistas)",
    612: "612 - Personas Físicas con Actividades Empresariales y Profesionales",
    614: "614 - Ingresos por intereses",
    615: "615 - Régimen de los ingresos por obtención de premios",
    616: "616 - Sin obligaciones fiscales",
    621: "621 - Incorporación Fiscal",
    625: "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
    626: "626 - Régimen Simplificado de confianza"
  };

  const invoiceUses = {
    CN01: "CN01 - Nómina",
    CP01: "CP01 - Pagos",
    D01: "D01 - Honorarios médicos, dentales y gastos hospitalarios",
    D02: "D02 - Gastos médicos por incapacidad o discapacidad",
    D03: "D03 - Gastos funerales",
    D04: "D04 - Donativos",
    D05: "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
    D06: "D06 - Aportaciones voluntarias al SAR",
    D07: "D07 - Primas por seguros de gastos médicos",
    D08: "D08 - Gastos de transportación escolar obligatoria",
    D09: "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
    D10: "D10 - Pagos por servicios educativos (colegiaturas)",
    G01: "G01 - Adquisición de mercancias",
    G02: "G02 - Devoluciones, descuentos o bonificaciones",
    G03: "G03 - Gastos en general",
    101: "101 - Construcciones",
    102: "102 - Mobilario y equipo de oficina por inversiones",
    103: "103 - Equipo de transporte",
    104: "104 - Equipo de computo y accesorios",
    105: "105 - Dados, troqueles, moldes, matrices y herramental",
    106: "106 - Comunicaciones telefónicas",
    107: "107 - Comunicaciones satelitales",
    108: "108 - Otra maquinaria y equipo",
    S01: "S01 - Sin efectos fiscales"
  };

  const wayToPayOptions = {
    '01': '01 - Efectivo',
    '02': '02 - Cheque nominativo',
    '03': '03 - Transferencia electrónica de fondos',
    '04': '04 - Tarjeta de crédito',
    '05': '05 - Monedero electrónico',
    '06': '06 - Dinero electrónico',
    '08': '08 - Vales de despensa',
    '12': '12 - Dación en pago',
    '13': '13 - Pago por subrogación',
    '14': '14 - Pago por consignación',
    '15': '15 - Condonación',
    '17': '17 - Compensación',
    '23': '23 - Novación',
    '24': '24 - Confusión',
    '25': '25 - Remisión de deuda',
    '26': '26 - Prescripción o caducidad',
    '27': '27 - A satisfacción del acreedor',
    '28': '28 - Tarjeta de débito',
    '29': '29 - Tarjeta de servicios',
    '30': '30 - Aplicación de anticipos',
    '31': '31 - Intermediarios',
    '99': '99 - Por definir'
  };

  const handleTaxRegime = (e) => {
    setTaxRegime(e.target.value);
  }

  const handleWayToPay = (e) => {
    setWayToPay(e.target.value);
  }

  const handlePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  }

  const handleKindOfPerson = (e) => {
    setKindOfPerson(e.target.value);
  }

  const decryptValue = (value) => {
    const decodedValue = decodeURIComponent(value);
    const bytes = CryptoJS.AES.decrypt(decodedValue, "hashmenufact");
    const originalValue = bytes.toString(CryptoJS.enc.Utf8);
    return originalValue;
  }

  useEffect(() => {

    let id = new URLSearchParams(search).get('id')
    let amount = new URLSearchParams(search).get('amount')
    let order = new URLSearchParams(search).get('order')
    if (!id) {
      showAfterLoading('error', 'Error', 'No hay datos para facturar').then((result) => {
        setDisableInput(true)
      });
    } else {
      const id_establishment = decryptValue(id)
      const total_amount = decryptValue(amount)
      const order_id = decryptValue(order)
      setEstablishmentId(id_establishment)
      setOrderId(order_id)
      setTicket({ order_id: order_id , amount: total_amount})
      getData(`/u/establishment/validate-establishment/${id_establishment}`).then((response) => {
        if(response.status === 400) toastDisplay('error', 'El establecimiento no puede facturar');
        const data = response.data.response.invoiceData;
        setEstablishment({
          cp: data.postal_code,
          establishment_id: data.establishment_id,
          name: data.name,
          pic: data.pic_profile,
          regime: data.tax_regime,
          rfc: data.rfc
        })
      })
    }
  }, []);


  const handleInput = (e) => {
    let id = e.target.id;
    switch (id) {
      case 'rfc':
        setRfc(e.target.value.toUpperCase());
        break;
      case 'name':
        setName(e.target.value.toUpperCase());
        break;
      case 'use':
        setUse(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'postalCode':
        setPostalCode(e.target.value);
        break;
      default:
        break;
    }
  }

  const downloadPdf = (base64) => {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    showAfterLoading('success', 'Correcto', 'Tu factura se ha descargado').then(response => {
      window.location.reload();
    })
  }

  const handleUserType = (e) => {
    setUseTypeUser(e.target.value);
    if (e.target.value == 1) {
      setAuxUseCfdi(useCfdi);
    } else if (e.target.value == 2) {
      let slice = useCfdi.slice(11, useCfdi.length - 1);
      setAuxUseCfdi(slice);
    }
  }

  const handleInvoiceUses = (e) => {
    setInvoiceUse(e.target.value);
  }

  const sendInvoice = ( ) => {
    if (rfc && rfc.trim().length && /[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(rfc)) {
      setRfcError(false);
    } else {
      setRfcError(true);
    }

    if (name && name.trim().length) {
      setNameError(false);
    } else {
      setNameError(true);
    }

    if (invoiceUse != 0) {
      setUseError(false);
    } else {
      setUseError(true);
    }

    if (email && email.trim().length) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if (paymentMethod != 0) {
      setPaymentError(false)
    } else {
      setPaymentError(true);
    }
  
    if (!name || !name.trim().length || !rfc || !rfc.trim().length || paymentMethod === 0) {
      toastDisplay('error', 'Completa todos los datos');
    } else {

      if (/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(rfc)) {
      let loading = createLoading('Procesando', 'En breve estará listo');
      loading.fire();

        const body = {
          establishment_id: establishmentId,
          order_id: orderId,
          payment_form: wayToPay,
          rfc: rfc,
          cfdi_use: invoiceUse,
          name: name,
          fiscal_regime: taxRegime,
          tax_zip_code: postalCode,
          kind_person:kindOfPerson,
          email_one: email,
          email_two: email,
          email_three: email,
        }

        post('/u/invoice/sendCFDI', body).then(response => {
          console.log(response)
          if(response.status === 400) {
            showAfterLoading('error', 'Error al generar factura', response.data.msg.Message || response.data.msg)
            return;
          } 
          const facturama_id = response.data.facturama_id

          getData(`/u/invoice/download/pdf/${facturama_id}`).then(response2 => {
            console.log(response2);
            if(response2.status === 400) toastDisplay('error', 'Error al generar la factura');

            confirm('Confirmación', 'Tu factura esta lista!', 'info', 'Descargar PDF', 'No', '#007bff', '#17a2b8').then(result => {
              if (result && result.isConfirmed) {
                 downloadPdf(response2.data.Content);
              }
            })
          })

        })
      } else {
        toastDisplay('error', 'El RFC es invalido');
      }
    }

  }

  return (
    <div>
      <div className='header-container'>
        <img className='header-img' src={yupikLogo} ></img>
      </div>

      <div className='establishment-container mx-auto'>

        {
          establishment && establishment.establishment_id != 0 &&
          <>
            <div className='establishment-img-container'>
              <img className='establishment-img' src={establishment && establishment.pic ? establishment.pic : ''} ></img>
            </div>
            <span className='establishment-name'>{establishment && establishment.name ? establishment.name : ''}</span>
            <br />
          </>

        }

      </div>

      <div className='billing-container mx-auto'>
        <h4 className='billing-start'>¡Inicia tu factura!</h4>

        <div className='grid-2'>
          <div className='form-group'>
            <label className='billing-label' > Monto total</label>
            <div className='float-icon-form'>
              <img className='float-icon' src={icn_money} />
              <input className='form-control form-float' type="text" value={ticket && ticket.amount ? '$' + ticket.amount : 0} disabled />
            </div>
          </div>
        </div>



        <div className='form-group'>
          <label className='billing-label'>Uso de la factura</label>
          <select onChange={handleInvoiceUses}
            className={paymentError ? 'form-control is-invalid' : 'form-control'}>
            <option value={''}>Seleccionar</option>
            {Object.keys(invoiceUses).map(llave => (
              <option value={llave}>{invoiceUses[llave]}</option>
            ))}
          </select>
        </div>

        <div className='form-group'>
          <label className='billing-label'>Regimen Fiscal</label>
          <select onChange={handleTaxRegime}
            className={paymentError ? 'form-control is-invalid' : 'form-control'}>
            <option value={''}>Seleccionar</option>
            {Object.keys(taxRegimeOptions).map(llave => (
              <option value={llave}>{taxRegimeOptions[llave]}</option>
            ))}
          </select>
        </div>

        <div className='form-group'>
          <label className='billing-label' >Método de pago</label>
          <select className={paymentError ? 'form-control is-invalid' : 'form-control'} onChange={handlePaymentMethod}>
            <option value={''}>Seleccionar</option>
            <option value={'PPD'}>PPD - Pago en parcialidades ó diferido</option>
            <option value={'PUE'}>PUE - Pago en una sola exhibición</option>
          </select>
        </div>

        <div className='form-group'>
          <label className='billing-label'>Formas de pago</label>
          <select onChange={handleWayToPay}
            className={paymentError ? 'form-control is-invalid' : 'form-control'}>
            <option value={''}>Seleccionar</option>
            {Object.keys(wayToPayOptions).map(llave => (
              <option value={llave}>{wayToPayOptions[llave]}</option>
            ))}
          </select>
        </div>

        <h4 className='billing-data'>Datos de facturación</h4>

        <div className='form-group'>
          <label className='billing-label' >RFC</label>
          <div className='float-icon-form'>
            <img className='float-icon' src={icn_rfc} />
            <input type="text" id='rfc' className={rfcError ? 'form-control form-float-2 is-invalid' : 'form-control form-float-2'} placeholder='RFC' onChange={handleInput} value={rfc} />
          </div>
          <span className='info-span'><img src={icn_info} className='info-image' />En mayúsculas y sin guiones</span>
        </div>
        <div className='form-group'>
          <label className='billing-label'>Tipo de persona</label>
          <select onChange={handleKindOfPerson}
            className={paymentError ? 'form-control is-invalid' : 'form-control'}>
            <option value={''}>Seleccionar</option>
            <option value={'fisica'}>Persona Física</option>
            <option value={'moral'}>Persona Moral</option>
          </select>
        </div>
        <div className='form-group'>
          <label className='billing-label'>Nombre / Razón Social</label>
          <div className='float-icon-form'>
            <img className='float-icon' src={icn_user} />
            <input type="text" id='name' className={nameError ? 'form-control form-float-2 is-invalid' : 'form-control form-float-2'} placeholder='Nombre o Razón Social' onChange={handleInput} value={name} />
          </div>
        </div>
        <div className='form-group'>
          <label className='billing-label' >Correo</label>
          <div className='float-icon-form'>
            <img className='float-icon' src={icn_email} />
            <input type="text" id='email' className={emailError ? 'form-control form-float-2 is-invalid' : 'form-control form-float-2'} placeholder='Correo' onChange={handleInput} value={email} />
          </div>
        </div>

        <div className='form-group'>
          <label className='billing-label' >Código Postal</label>
          <div className='float-icon-form'>
            <input type="text" id='postalCode' placeholder='Código Postal' onChange={handleInput} value={postalCode}
              className={nameError ? 'form-control form-float-2 is-invalid' : 'form-control form-float-2'} />
          </div>
        </div>

      </div>
      <div className='send-container mx-auto'>
        <button className=' btn-emit-bill' onClick={sendInvoice} disabled={disableInput}>Emitir factura</button>
      </div>
    </div>
  );
}

export default App;