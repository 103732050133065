class Cookies {

    setC(cname, cvalue, exdays, callBack) {
		this.setCookie(cname, cvalue, exdays);

		if (callBack) {
			callBack();
		}
	}

	getC(cname) {
		return this.getCookie(cname);
	}

	delC(cname) {
		this.deleteCookie(cname);
	}

	get(type = 'data') {
		let user = this.getC(type);
		if (user.length) {
			return JSON.parse(user)
		}
		return null;
	}

	setCookie(cname, cvalue, exdays) {
		let d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		let expires = "expires="+d.toUTCString();
		document.cookie = btoa(cname) + "=" + btoa(cvalue) + ";" + expires + ";path=/";
	}

	getCookie(cname) {
		let name = btoa(cname) + "=";
		let  ca = document && document.cookie ? document.cookie.split(';'):[];
		let response = '';
		for(var i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				response = atob(c.substring(name.length, c.length));
			}
		}

		if (response.length) {
			return atob(response);
		}
		return response;
	}

	deleteCookie(name) {
		document.cookie = btoa(name) +'=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
}
const CookiesService = new Cookies();

export default CookiesService;
